import { Link } from "gatsby"
import React from "react"
import Image from "../components/Image"

const TeackerLink = () => (
    <section className="o-section">
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-xl-8 mx-auto">
                    <Link to="/votre-enseignante" className="c-teacher-link u-py:2 u-px:1 u-py-md:3 u-px-md:3">
                        <div className="c-teacher-link__img">
                            <Image alt="Maryine Demars" filename="maryline-demars.jpg"/>
                        </div>
                        <div className="c-teacher-link__header">
                            <span className="u-color:dark-theme"><strong>Votre enseignante</strong></span>
                            <h3 className="-lite u-color:dark-theme" style={{ marginTop: '-6px'}}>Maryline Demars</h3>
                        </div>
                        <div className="c-teacher-link__body">
                            <p className="ts:small u-mt:1 u-color:dark-theme">Professeur d'Hatha Yoga, et Yoga-Nidra.<br/>Sophrologue diplômée et praticienne PNL. <br/>Formée au Yoga des Yeux/ Méthode BATES</p>
                        </div>

                    </Link>
                </div>
            </div>
        </div>
    </section>

)

export default TeackerLink
