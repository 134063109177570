import React from "react"

const Quote = ( {body} ) => (
    <section className="o-section -quote">
        <div className="container">
            <div className="row">
                <div className="col-lg-10 col-xl-8 mx-auto">
                    <div className="c-quote">
                        <div className="u-py:2 ts:lead u-ta:c u-color:theme" dangerouslySetInnerHTML={{ __html: body }} />
                    </div>
                </div>
            </div>
        </div>
    </section>

)

export default Quote
